<div *ngIf="selectedChemical == undefined && !summaryMode" class="alert alert-warning alert-dismissible fade show" role="alert">
  <strong>Hi!</strong> The section below will fill in as you go with your research. Start with selecting a chemical.
  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="card bg-light mb-3">
  <div class="card-body">

    <!-- Progress bar - for HTTP activity -->
    <div *ngIf="isLoading | async" class="mb-3">
      <mat-progress-bar class="example-margin" [color]="color" [mode]="mode" [value]="value"></mat-progress-bar>
    </div>

    <h4 class="card-title">Selected chemical</h4>
    <p *ngIf="selectedChemical == undefined" class="card-text">No chemical selected.</p>
    <tb-chemview *ngIf="selectedChemical != undefined" [chemId]="selectedChemical.ChemId"></tb-chemview>

    <h4 class="card-title">Profiling</h4>
    <p *ngIf="profilingResults == undefined" class="card-text">No profiling results yet. <a *ngIf="selectedChemical != undefined  && !summaryMode" routerLink="/profiling">Go to Profiling.</a></p>

    <div *ngIf="profilingResults != undefined" class="col-md-12">
      <table class="table">
        <thead>
          <tr>
            <th>Profiler</th>
            <th>Results</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let profiler of profilingResults.keys()">
            <td>{{profiler}}</td>
            <!-- <td><span *ngFor="let result of profilingResults.get(profiler)">{{result}}<br></span></td> -->
            <td><a *ngFor="let result of profilingResults.get(profiler)" (click)="showLiterature(profiler, result)" href="javascript:void(0)">{{result}}<br></a></td>
          </tr>
        </tbody>
      </table>
    </div>

    <h4 class="card-title">Calculations</h4>
    <p *ngIf="calculationResults == undefined" class="card-text">No calculation results yet. <a *ngIf="selectedChemical != undefined  && !summaryMode" routerLink="/calculations">Go to Calculations.</a></p>

    <div *ngIf="calculationResults != undefined" class="col-md-12">
      <table class="table">
        <thead>
          <tr>
            <th>Calculator</th>
            <th>Results</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let calculator of calculationResults.keys()">
            <td>{{calculator}}</td>
            <td>{{calculationResults.get(calculator)}}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <h4 class="card-title">Metabolism</h4>
    <p *ngIf="metabolismResults == undefined" class="card-text">No metabolism yet. <a *ngIf="selectedChemical != undefined  && !summaryMode" routerLink="/metabolism">Go to Metabolism.</a></p>

    <div *ngIf="metabolismResults != undefined" class="col-md-12">
      <table class="table">
        <thead>
          <tr>
            <th>Simulator</th>
            <th>Metabolites</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let simulator of metabolismResults.keys()">
            <td>{{simulator}}</td>
            <td><a *ngFor="let metabolite of metabolismResults.get(simulator)" (click)="showMetabolite(metabolite)" href="javascript:void(0)">{{metabolite}}<br></a></td>
          </tr>
        </tbody>
      </table>
    </div>

    <h4 class="card-title">Data</h4>
    <p *ngIf="dataResults == undefined" class="card-text">No data yet. <a *ngIf="selectedChemical != undefined  && !summaryMode" routerLink="/data">Go to Data.</a></p>

    <div *ngIf="dataResults != undefined" class="col-md-12">
      <table class="table">
        <thead>
          <tr>
            <th>Endpoint</th>
            <th>Data</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let endpoint of dataResults.keys()">
            <td>{{endpoint}}</td>
            <td><span *ngFor="let result of dataResults.get(endpoint)">{{result}}<br></span></td>
          </tr>
        </tbody>
      </table>
    </div>

    <h4 class="card-title">QSAR</h4>
    <p *ngIf="qsarResults == undefined" class="card-text">No QSAR results yet. <a *ngIf="selectedChemical != undefined  && !summaryMode" routerLink="/qsar">Go to QSAR.</a></p>

  </div>
</div>

<!-- Button trigger modal -->
<button id="modalButton" type="button" hidden data-toggle="modal" data-target="#modalDialog"></button>

<!-- Modal -->
<div class="modal fade" id="modalDialog" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">{{modalTitle}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div id="modalContent" class="modal-body">
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>