/*       QSAR Toolbox Web Client
Service for Cross-component communication */

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { Chemical } from '../models/chemical';
import { Profiler } from '../models/profiler';
import { IuclidDatabase } from '../models/iuclid-database';
import { ProfilingResults, CalculationResults, MetabolismResults, DataResults, QsarResults } from '../models/results';

@Injectable()
export class Intercom {

    allProfilers: Profiler[];
    allIuclidDatabases: IuclidDatabase[];

    // State holders
    selectedChemical: Chemical;
    selectedProfilers: string[];

    profilingResults: ProfilingResults;
    calculationResults: CalculationResults;
    metabolismResults: MetabolismResults;
    dataResults: DataResults;
    qsarResults: QsarResults;

    private chemicalSelectedSource = new Subject<Chemical>();
    private chemicalProfiledSource = new Subject<ProfilingResults>();
    private chemicalCalculatedSource = new Subject<CalculationResults>();
    private chemicalMetabolizedSource = new Subject<MetabolismResults>();
    private chemicalDataSource = new Subject<DataResults>();
    private chemicalQsarSource = new Subject<QsarResults>();

    chemicalSelected$ = this.chemicalSelectedSource.asObservable();
    chemicalProfiled$ = this.chemicalProfiledSource.asObservable();
    chemicalCalculated$ = this.chemicalCalculatedSource.asObservable();
    chemicalMetabolized$ = this.chemicalMetabolizedSource.asObservable();
    chemicalData$ = this.chemicalDataSource.asObservable();
    chemicalQsar$ = this.chemicalQsarSource.asObservable();

    isLoading = new Subject<boolean>();
    show() {
        this.isLoading.next(true);
    }
    hide() {
        this.isLoading.next(false);
    }

    clearChemicalResults() {
        this.profilingResults = undefined;
        this.calculationResults = undefined;
        this.metabolismResults = undefined;
        this.dataResults = undefined;
        this.qsarResults = undefined;
    }

    setChemical(chemical: Chemical) {
        this.chemicalSelectedSource.next(chemical);
        this.selectedChemical = chemical;
        this.clearChemicalResults();
    }

    setProfilingResults(results: ProfilingResults) {
        this.chemicalProfiledSource.next(results);
        this.profilingResults = results;
    }

    setCalculationResults(results: CalculationResults) {
        this.chemicalCalculatedSource.next(results);
        this.calculationResults = results;
    }

    setMetabolismResults(results: MetabolismResults) {
        this.chemicalMetabolizedSource.next(results);
        this.metabolismResults = results;
    }

    setDataResults(results: DataResults) {
        this.chemicalDataSource.next(results);
        this.dataResults = results;
    }

    setQsarResults(results: QsarResults) {
        this.chemicalQsarSource.next(results);
        this.qsarResults = results;
    }

    getProfilerByCaption(caption: string): Profiler {
        let result: null;
        for (let profiler of this.allProfilers) {
            if (profiler.Caption == caption) {
                return profiler;
            }
        }
        return result;
    }

    getProfilerGuidByCaption(caption: string): string {
        return this.getProfilerByCaption(caption).Guid;
    }

    generateExport(): string {
        var content = "Toolbox WebClient Export\n";
        content += "\n"

        content += "1. Selected Chemical\n";
        content += "CAS: " + this.selectedChemical.Cas + "\n";
        content += "Name: " + this.selectedChemical.Names + "\n";
        content += "SMILES: " + this.selectedChemical.Smiles + "\n";
        content += "\n"

        if (this.profilingResults != undefined) {
            content += "2. Profiling\n";
            for (let entry of Array.from(this.profilingResults.entries())) {
                content += entry[0] + ": " + entry[1] + "\n";
            }
            content += "\n"
        }

        if (this.calculationResults != undefined) {
            content += "3. Calculations\n";
            for (let entry of Array.from(this.calculationResults.entries())) {
                content += entry[0] + ": " + entry[1] + "\n";
            }
            content += "\n"
        }

        if (this.metabolismResults != undefined) {
            content += "4. Metabolism\n";
            for (let entry of Array.from(this.metabolismResults.entries())) {
                content += entry[0] + ": " + entry[1] + "\n";
            }
            content += "\n"
        }

        if (this.dataResults != undefined) {
            content += "5. Data\n";
            for (let entry of Array.from(this.dataResults.entries())) {
                content += entry[0] + ": " + entry[1] + "\n";
            }
            content += "\n"
        }

        if (this.qsarResults != undefined) {
            content += "6. QSAR\n";
            for (let entry of Array.from(this.qsarResults.entries())) {
                content += entry[0] + ": " + entry[1] + "\n";
            }
            content += "\n"
        }
        
        return content;
    }
}