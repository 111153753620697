<div class="container-fluid">
    <div class="row">
        <!-- search tabs -->
        <div class="col-6">

            <h2>Search</h2>

            <nav class="nav nav-tabs" id="myTab" role="tablist">
                <a class="nav-item nav-link active" id="nav-smiles-tab" data-toggle="tab" href="#nav-smiles" role="tab"
                    aria-controls="nav-smiles" (click)="clearResults()">Structure</a>                
                <a class="nav-item nav-link" id="nav-cas-tab" data-toggle="tab" href="#nav-cas" role="tab"
                    aria-controls="nav-cas" (click)="clearResults()">CAS#</a>
                <a class="nav-item nav-link" id="nav-ecnumber-tab" data-toggle="tab" href="#nav-ecnumber" role="tab"
                    aria-controls="nav-ecnumber" (click)="clearResults()">EC Number</a>
                <a class="nav-item nav-link" id="nav-name-tab" data-toggle="tab" href="#nav-name" role="tab"
                    aria-controls="nav-name" (click)="clearResults()">Chemical name</a>
            </nav>

            <br />

            <div class="tab-content" id="nav-tabContent">

                <!-- SMILES Input -->
                <div class="tab-pane fade show active" id="nav-smiles" role="tabpanel" aria-labelledby="nav-smiles-tab">
                    <p>Search entities by structure or fragment</p>

                    <div class="input-group col-md-10">
                        <div class="input-group-prepend">
                            <span class="input-group-text">SMILES: </span>
                        </div>

                        <div class="input-group-prepend">
                            <select [(ngModel)]="smiles_option" #exact_or_fragment size="1">
                                <option value="Exactmatch">Exact match</option>
                                <option value="Fragment">Fragment</option>
                            </select>
                        </div>

                        <input type="text" class="form-control" #smiles id="smiles" autocomplete="off"
                            [value]="editorSmiles" (keyup.enter)="findChemicalsBySmiles(smiles.value)">

                        <div class="input-group-append">
                            <button class="btn btn-primary" type="button"
                                (click)="iframe.src='http://storage.oasis-lmc.org/STemelkov/2d/2021/?sess=' + getRandomString() + '&type=' + getEditorType() + '&chemical=' + encode(smiles.value)"
                                data-toggle="modal" data-target="#editorModal">
                                <i class="fas fa-pencil-alt"></i>
                            </button>
                            <button class="btn btn-primary" type="button"
                                (click)="findChemicalsBySmiles(smiles.value)">
                                <i class="fas fa-search"></i>
                            </button>
                        </div>
                    </div>

                    <!-- 2D Modal  -->
                    <div class="modal fade" id="editorModal" data-backdrop="static" data-keyboard="false" tabindex="-1">
                        <div class="modal-dialog modal-xl modal-dialog-centered">
                            <div class="modal-content">
                                <div class="modal-body">
                                    <iframe id="iframe" #iframe frameBorder="0" width="1280px" height="640px"></iframe>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-primary" data-dismiss="modal"
                                        (click)="showCurrentSmiles()">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <!-- CAS Input -->
                <div class="tab-pane fade" id="nav-cas" role="tabpanel" aria-labelledby="nav-cas-tab">
                    <p>Search entities by CAS number</p>

                    <div class="input-group col-md-10">
                        <div class="input-group-prepend">
                            <span class="input-group-text">CAS: </span>
                        </div>

                        <input type="text" class="form-control" #cas id="cas" autocomplete="off"
                            (keyup.enter)="findChemicalsByCas(cas.value)">

                        <div class="input-group-append">
                            <button class="btn btn-primary" type="button"
                                (click)="findChemicalsByCas(cas.value)">
                                <i class="fas fa-search"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <!-- EC Number Input -->
                <div class="tab-pane fade" id="nav-ecnumber" role="tabpanel" aria-labelledby="nav-ecnumber-tab">
                    <p>Search entities by EC Number</p>
                    <div class="input-group col-md-10">
                        <div class="input-group-prepend">
                            <span class="input-group-text">EC Number: </span>
                        </div>

                        <input type="text" class="form-control" #ecnumber id="ecnumber" autocomplete="off"
                            (keyup.enter)="findChemicalsByEcNumber(ecnumber.value)">

                        <div class="input-group-append">
                            <button class="btn btn-primary" type="button"
                                (click)="findChemicalsByEcNumber(ecnumber.value)">
                                <i class="fas fa-search"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <!-- Name Input -->
                <div class="tab-pane fade" id="nav-name" role="tabpanel" aria-labelledby="nav-name-tab">
                    <p>Search entities by Chemical Name</p>

                    <div class="input-group col-md-10">
                        <div class="input-group-prepend">
                            <span class="input-group-text">Name: </span>
                        </div>

                        <!-- <div class="input-group-prepend">
                            <select #option size="1">
                                <option value="0">Is</option>
                                <option value="1">Starts with</option>
                                <option value="2">Contains</option>
                            </select>
                        </div> -->

                        <input type="text" class="form-control" #name id="name"
                            (keyup.enter)="findChemicalsByName(name.value)">

                        <div class="input-group-append">
                            <button class="btn btn-primary" type="button"
                                (click)="findChemicalsByName(name.value)">
                                <i class="fas fa-search"></i>
                            </button>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <!-- Search options -->
        <div class="col-6">
            <div class="row">
                <div class="col">
                    <h2>Scope</h2>
                </div>
                <div class="col">
                    <button mat-flat-button color="primary" class="float-right" (click)="selectAllOptions()">Select all</button>
                </div>
            </div>
            <tb-iuclidscope [databases]="databases"></tb-iuclidscope>
        </div>
    </div>
    
    <!-- Weird empty row? -->
    <div class="row">
        <br>
    </div>

    <div *ngIf="searchInProgress" class="row">
        <div class="col">
            Searching...    
            <mat-progress-bar mode="query"></mat-progress-bar>        
        </div>
    </div>

    <div *ngIf="resultsVisible" class="row">
        <div class="col">
            Search for: {{lastSearch}}
            <tb-iuclidresults [chemicals]="chemicals"></tb-iuclidresults>
        </div>
    </div>

    <div *ngIf="errorMessage" class="row">
        <div class="col">
            Error: {{errorMessage}}
        </div>
    </div>

    <br><br><br>

</div>