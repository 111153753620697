import { Component, Output } from '@angular/core';

@Component({
  selector: 'tb-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css']
})
export class FilterComponent {

  @Output() value: string = "";

  onKey(text: string) {
    this.value = text;
  }

}