import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { InputComponent } from './sections/input/input.component';
import { ProfilingComponent } from './sections/profiling/profiling.component';
import { CalculationsComponent } from './sections/calculations/calculations.component';
import { MetabolismComponent } from './sections/metabolism/metabolism.component';
import { SummaryComponent } from './sections/summary/summary.component';
import { DataComponent } from './sections/data/data.component';
import { QsarComponent } from './sections/qsar/qsar.component';
import { IuclidComponent } from './sections/iuclid/iuclid.component';

const routes: Routes = [
  { path: '', redirectTo: '/input', pathMatch: 'full' },
  { path: 'input', component: InputComponent },
  { path: 'profiling', component: ProfilingComponent },
  { path: 'calculations', component: CalculationsComponent },
  { path: 'metabolism', component: MetabolismComponent },
  { path: 'data', component: DataComponent },
  { path: 'qsar', component: QsarComponent },
  { path: 'summary', component: SummaryComponent },
  { path: 'iuclid', component: IuclidComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})

export class AppRoutingModule { }