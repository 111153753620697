/*       QSAR Toolbox Web Client
Service for generating exports      */

import { Injectable } from '@angular/core';
import { Intercom } from './intercom.service';

@Injectable()
export class ExportService {

    constructor(private intercom: Intercom) { }

    generateExport(): string {
        var content = "Toolbox WebClient Export\n";
        content += "\n"

        content += "1. Selected Chemical\n";
        if (this.intercom.selectedChemical != undefined) {
            content += "CAS: " + this.intercom.selectedChemical.Cas + "\n";
            content += "Name: " + this.intercom.selectedChemical.Names + "\n";
            content += "SMILES: " + this.intercom.selectedChemical.Smiles + "\n";
        } else {
            content += "N/A\n";
        }
        content += "\n"

        content += "2. Profiling\n";
        if (this.intercom.profilingResults != undefined) {
            for (let entry of Array.from(this.intercom.profilingResults.entries())) {
                content += entry[0] + ": " + entry[1] + "\n";
            }
        } else {
            content += "N/A\n";
        }
        content += "\n"

        content += "3. Calculations\n";
        if (this.intercom.calculationResults != undefined) {
            for (let entry of Array.from(this.intercom.calculationResults.entries())) {
                content += entry[0] + ": " + entry[1] + "\n";
            }
        } else {
            content += "N/A\n";
        }
        content += "\n"

        content += "4. Metabolism\n";
        if (this.intercom.metabolismResults != undefined) {
            for (let entry of Array.from(this.intercom.metabolismResults.entries())) {
                content += entry[0] + ": " + entry[1] + "\n";
            }
        } else {
            content += "N/A\n";
        }
        content += "\n"

        content += "5. Data\n";
        if (this.intercom.dataResults != undefined) {
            for (let entry of Array.from(this.intercom.dataResults.entries())) {
                content += entry[0] + ": " + entry[1] + "\n";
            }
        } else {
            content += "N/A\n";
        }
        content += "\n"

        content += "6. QSAR\n";
        if (this.intercom.qsarResults != undefined) {
            for (let entry of Array.from(this.intercom.qsarResults.entries())) {
                content += entry[0] + ": " + entry[1] + "\n";
            }
        } else {
            content += "N/A\n";
        }
        content += "\n"

        return content;
    }
}