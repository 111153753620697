import { Component, OnInit } from '@angular/core';

import { Simulator } from '../../models/simulator';
import { MetabolismResults } from '../../models/results';
import { ListEvent } from '../../components/listview/listevent';
import { ToolboxService } from '../../services/toolbox.service';
import { Intercom } from '../../services/intercom.service';

@Component({
  selector: 'tb-section-metabolism',
  templateUrl: './metabolism.component.html',
  styleUrls: ['./metabolism.component.css']
})

export class MetabolismComponent implements OnInit {

  // Holds all available simulators
  simulators: Simulator[];
  
  // Holds the simulators, selected by the user
  selected: string[] = [];
  
  // Holds the results from the metabolism
  results: MetabolismResults = new Map<string, string[]>();

  constructor(private toolboxService: ToolboxService, private intercom: Intercom) { }

  ngOnInit() {
    // Retrieve all available simulators
    this.toolboxService.getSimulators()
      .subscribe((simulators: Simulator[]) => this.simulators = simulators);
  }

  onItemChecked(event: ListEvent) {
    if (event.Checked)
      this.selected.push(event.Guid)
    else {
      var index = this.selected.indexOf(event.Guid, 0);
      if (index > -1) {
        this.selected.splice(index, 1);
      }
    }
  }

  apply() {
    if (this.selected.length == 0) {
      window.alert("No simulators selected");
      return;
    }

    if (this.intercom.selectedChemical == undefined) {
      window.alert("No target chemical");
      return;
    }

    this.results.clear();
    for (let simulator of this.selected) {
      this.toolboxService.getMetabolites(this.intercom.selectedChemical.ChemId, simulator)
        .subscribe((metabolites: string[]) => this.receiveResults(simulator, metabolites));
    }
    // Go to the top of the page
    window.scrollTo(0, 0);
  }

  getSimulatorName(simulatorGuid: string) {
    let result: string = "";
    for (let simulator of this.simulators) {
      if (simulator.Guid == simulatorGuid) {
        return simulator.Caption;
      }
    }
    return result;
  }

  receiveResults(simulatorGuid: string, metabolites: string[]) {
    this.results.set(this.getSimulatorName(simulatorGuid), metabolites);
    this.intercom.setMetabolismResults(this.results);
  }

}
