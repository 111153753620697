import { Component, OnInit } from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { of as observableOf } from 'rxjs';

import { ToolboxService } from '../../services/toolbox.service';
import { Intercom } from '../../services/intercom.service';
import { EndpointTree } from '../../models/endpoint-tree';
import { Qsar } from '../../models/qsar';
import { EndpointTreeItem } from '../../models/endpoint-tree-item';
import { DataPoint } from '../../models/data-point';
import { DataResults } from '../../models/results';

/** Flat node with expandable and level information */
export interface TreeNode {
  name: string;
  path: string;
  level: number;
  expandable: boolean;
}

@Component({
  selector: 'app-qsar',
  templateUrl: './qsar.component.html',
  styleUrls: ['./qsar.component.css']
})
export class QsarComponent implements OnInit {
  /** The TreeControl controls the expand/collapse state of tree nodes.  */
  treeControl: FlatTreeControl<TreeNode>;

  /** The TreeFlattener is used to generate the flat list of items from hierarchical data. */
  treeFlattener: MatTreeFlattener<EndpointTreeItem, TreeNode>;

  /** The MatTreeFlatDataSource connects the control and flattener to provide data. */
  dataSource: MatTreeFlatDataSource<EndpointTreeItem, TreeNode>;

  // Holds the data results
  results: DataResults = new Map<string, string[]>();

  endpointTree: EndpointTree;
  qsars: Qsar[];
  selectedPosition: string;
  selectedQsar: string;
  retrievedData: DataPoint[];

  constructor(private toolboxService: ToolboxService, private intercom: Intercom) { 
    this.qsars = [];
    
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren);
  
    this.treeControl = new FlatTreeControl<TreeNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  }

  /** Transform the data object to something the tree can read. */
  transformer(node: EndpointTreeItem, level: number) {
    return {
      name: node.caption,
      path: node.path,
      level: level,
      expandable: !!node.children && node.children.length > 0
    };
  }

  /** Get the level of the node */
  getLevel(node: TreeNode) {
    return node.level;
  }

  /** Return whether the node is expanded or not. */
  isExpandable(node: TreeNode) {
    return node.expandable;
  };

  /** Get the children for the node. */
  getChildren(node: EndpointTreeItem) {
    return observableOf(node.children);
  }

  /** Get whether the node has children or not. */
  hasChild(index: number, node: TreeNode) {
    return node.expandable;
  }

  ngOnInit(): void {
    // Retrieve the endpoint tree
    this.getEndpointTree();
  }

  private getEndpointTree() {
    this.toolboxService.getEndpointTree().subscribe(
      (treeList: string[]) => {
        this.endpointTree = new EndpointTree(treeList);
        this.dataSource.data = this.endpointTree.dumpToList();
        console.log(this.endpointTree.dumpToList());
      }
    );
  }

  selectPosition(position: string) {
    if (this.selectedPosition != position) {
      this.retrievedData = undefined;
      this.results.clear();
    }
    this.selectedPosition = position;
    this.getQsars();
  }
  
  selectQsar(qsar: string) {
    if (this.selectedQsar != qsar) {
      this.retrievedData = undefined;
      this.results.clear();
    }
    this.selectedQsar = qsar;
  }

  getQsars() {
    this.qsars = [];
    this.toolboxService.getQsars(this.selectedPosition)
    .subscribe((qsars: Qsar[]) => this.updateQsars(qsars));
  }

  getData() {
    if (this.intercom.selectedChemical == undefined) {
      window.alert("No target chemical");
      return;
    }

    this.results.clear();

    this.toolboxService.applyQsar(this.selectedQsar, this.intercom.selectedChemical.ChemId)
      .subscribe((datapoints: DataPoint[]) => this.updateResult(datapoints));
  }

  updateQsars(qsars: Qsar[]) {
    this.qsars = qsars.sort((one, two) => (one._caption > two._caption ? 1 : -1));
  }

  updateResult(datapoints: DataPoint[]) {
    this.retrievedData = datapoints;

    var summaryResults = [];
    for (let datapoint of datapoints) {
      summaryResults.push(datapoint.Value + ' ' + datapoint.Unit);
    }

    this.results.set(this.selectedQsar, summaryResults);
    this.intercom.setDataResults(this.results);
  }

}
