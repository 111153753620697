import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Profiler } from '../../models/profiler';
import { ProfilingResults } from '../../models/results';
import { ListEvent } from '../../components/listview/listevent';
import { LiteratureBrowserComponent } from '../../dialogs/literature-browser/literature-browser.component';
import { ToolboxService } from '../../services/toolbox.service';
import { Intercom } from '../../services/intercom.service';

@Component({
  selector: 'tb-section-profiling',
  templateUrl: './profiling.component.html',
  styleUrls: ['./profiling.component.css']
})

export class ProfilingComponent implements OnInit {

  // Holds all available profilers, grouped by type
  profilers: Map<string, Profiler[]>;
  
  // Holds the profilers, selected by the user
  selected: string[] = [];

  // Holds the results from the profiling
  results: ProfilingResults = new Map<string, string[]>();

  private _profilers: Profiler[];
  
  constructor(private toolboxService: ToolboxService, private intercom: Intercom, public dialog: MatDialog) { }

  ngOnInit(): void {
    // Retrieve all available profilers
    this.getProfilers();
  }

  getProfilers(): void {
    if (this.toolboxService.profilers != undefined) {
      this.profilers = this.groupProfilers(this.toolboxService.profilers)
    } else {
      this.toolboxService.getProfilers()
        .subscribe((profilers: Profiler[]) => {
          this.profilers = this.groupProfilers(profilers);
          this.intercom.allProfilers = profilers;
        });
    }
  }

  groupProfilers(profilers: Profiler[]): Map<string, Profiler[]> {

    // save a reference to the the original list
    this._profilers = profilers;

    let groupedProfilers = new Map<string, Profiler[]>();

    for (let profiler of profilers) {

      // Filter hidden profilers
      //if (!profiler.IsVisible)
      if (profiler.Caption.startsWith('Category') || profiler.Caption == 'ERB')
        continue;

      // add a new empty array if type does not exist yet
      if (!groupedProfilers.has(profiler.Type)) {
        let list: Profiler[] = [];
        groupedProfilers.set(profiler.Type, list);
      }

      // add the profiler to the array associated to its type
      groupedProfilers.get(profiler.Type).push(profiler);
    }

    return groupedProfilers;
  }

  applyProfiling() {
    if (this.selected.length == 0) {
      window.alert("No profilers selected");
      return;
    }

    if (this.intercom.selectedChemical == undefined) {
      window.alert("No target chemical");
      return;
    }

    this.results.clear();
    for (let profiler of this.selected) {
      this.toolboxService.getProfiles(this.intercom.selectedChemical.ChemId, profiler)
        .subscribe((results: string[]) => this.receiveResults(profiler, results));
    }
    // Go to the top of the page
    window.scrollTo(0, 0);
  }

  showCategories(profilerName: string, profilerGuid: string, categories: string[]) {
    let dialogRef = this.dialog.open(LiteratureBrowserComponent, 
      {width: '90%', 
      height: '650px', 
      data: {profilerName: profilerName, profilerGuid: profilerGuid, categories: categories}});
  }

  getProfilerNameByGuid(profilerGuid: string): string {
    let result: string = "";
    for (let profiler of this._profilers) {
      if (profiler.Guid == profilerGuid) {
        return profiler.Caption;
      }
    }
    return result;
  }

  receiveResults(profilerGuid: string, results: string[]) {
    this.results.set(this.getProfilerNameByGuid(profilerGuid), results);
    this.intercom.setProfilingResults(this.results);
  }

  onItemChecked(event: ListEvent) {
    if (event.Checked)
      this.selected.push(event.Guid)
    else {
      var index = this.selected.indexOf(event.Guid, 0);
      if (index > -1) {
        this.selected.splice(index, 1);
      }
    }
  }

  onViewRequest(event: ListEvent) {
    var profilerGuid = event.Guid;
    var profilerName = this.getProfilerNameByGuid(profilerGuid);
    this.toolboxService.getProfilerCategories(profilerGuid)
    .subscribe((categories: string[]) => this.showCategories(profilerName, profilerGuid, categories));
  }

}
