import { OnInit, Component } from '@angular/core';
import { Router } from '@angular/router';

import { ToolboxService } from './services/toolbox.service';

@Component({
  selector: 'toolbox',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.css' ]
})

export class AppComponent implements OnInit {

  constructor(private router: Router, private toolboxService: ToolboxService) { }

  webApiUrl: string;

  ngOnInit(): void {
    this.webApiUrl = this.toolboxService.getWebApiUrl();
  }

  currentRoute(): string {
    return this.router.url;
  }

  saveurl(url: string) {
    this.toolboxService.setUrl(url);
  }
}
