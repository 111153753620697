<h2>Input</h2>
<nav class="nav nav-tabs" id="myTab" role="tablist">
    <a class="nav-item nav-link active" id="nav-cas-tab" data-toggle="tab" href="#nav-cas" role="tab"
        aria-controls="nav-cas" aria-expanded="true" (click)=clearResults()>CAS#</a>
    <a class="nav-item nav-link" id="nav-name-tab" data-toggle="tab" href="#nav-name" role="tab"
        aria-controls="nav-name" (click)=clearResults()>Chemical name</a>
    <a class="nav-item nav-link" id="nav-smiles-tab" data-toggle="tab" href="#nav-smiles" role="tab"
        aria-controls="nav-smiles" (click)=clearResults()>SMILES</a>    
</nav>
<br>

<div class="tab-content" id="nav-tabContent">

    <!-- CAS Input -->
    <div class="tab-pane fade show active" id="nav-cas" role="tabpanel" aria-labelledby="nav-cas-tab">
        <p>Search for chemicals by CAS number</p>
    
        <div class="input-group col-md-10">    
            <div class="input-group-prepend">
                <span class="input-group-text">CAS: </span>
            </div>
        
            <input type="text" class="form-control" #cas id="cas" autocomplete="off"
                (keyup.enter)="findChemicalsByCas(cas.value); cas.value=''">
    
            <div class="input-group-append">
                <button class="btn btn-primary" type="button" (click)="findChemicalsByCas(cas.value); cas.value=''">
                    <i class="fas fa-search"></i>
                </button>
            </div>
        </div>
    </div>

    <!-- Name Input -->
    <div class="tab-pane fade" id="nav-name" role="tabpanel" aria-labelledby="nav-name-tab">
        <p>Search for chemicals by Chemical Name</p>

        <div class="input-group col-md-10">
            <div class="input-group-prepend">
                <span class="input-group-text">Name: </span>
            </div>

            <div class="input-group-prepend">
                <select #option size="1">
                    <option value="0">Is</option>
                    <option value="1">Starts with</option>
                    <option value="2">Contains</option>
                </select>
            </div>

            <input type="text" class="form-control" #name id="name"
                (keyup.enter)="findChemicalsByName(name.value, option.value); name.value=''">

            <div class="input-group-append">
                <button class="btn btn-primary" type="button"
                    (click)="findChemicalsByName(name.value, option.value); name.value=''">
                    <i class="fas fa-search"></i>
                </button>
            </div>
        </div>

    </div>

    <!-- SMILES Input -->
    <div class="tab-pane fade" id="nav-smiles" role="tabpanel" aria-labelledby="nav-smiles-tab">
        <p>Search for chemicals by SMILES</p>

        <div class="input-group col-md-10">
            <div class="input-group-prepend">
                <span class="input-group-text">SMILES: </span>
            </div>
            
            <input type="text" class="form-control" #smiles id="smiles" autocomplete="off" [value]="editorSmiles"
                (keyup.enter)="findChemicalsBySmiles(smiles.value); smiles.value=''">

            <div class="input-group-append">
                <button class="btn btn-primary" type="button"
                    (click)="iframe.src='http://storage.oasis-lmc.org/STemelkov/2d/2018/?smiles=' + encode(smiles.value)"
                    data-toggle="modal" data-target="#editorModal">
                    <i class="fas fa-pencil-alt"></i>
                </button>
                <button class="btn btn-primary" type="button"
                    (click)="findChemicalsBySmiles(smiles.value); smiles.value=''">
                    <i class="fas fa-search"></i>
                </button>
            </div>
        </div>

        <!-- 2D Modal  -->
        <div class="modal fade" id="editorModal" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-body" width="100%" height="100%">
                        <!-- <iframe src="http://storage.oasis-lmc.org/STemelkov/2d/2017/" frameBorder="0" width="1100px" height="600px"></iframe> -->
                        <iframe id="iframe" #iframe frameBorder="0" width="1340px" height="640px"></iframe>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" data-dismiss="modal"
                            (click)="showCurrentSmiles()">Close</button>
                    </div>
                </div>
            </div>
        </div>
        
    </div>

</div>

<!-- Results output -->
<div *ngIf="chemIds != undefined && chemIds.length > 0">
    <h3>Results: </h3>
    <div>
        {{lastSearch}}
        <button (click)="clearResults()">X</button>
    </div>
    <div>
        Showing the first {{shownChemicals}} of all
        <strong>{{chemIds.length}}</strong> chemical
        <span *ngIf="chemIds.length > 1">s</span> found.
    </div>
    <div *ngFor="let chemId of chemIds; let i = index">
        <tb-chemview #chemview *ngIf="i < searchLimit" [chemId]="chemId" (click)="selectChemical(chemview.chemical)">
        </tb-chemview>
    </div>
    <br>
</div>