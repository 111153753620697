<h2>Profiling</h2>

<div *ngIf="profilers != undefined">
    <tb-filter #filter></tb-filter>
    <br>
    
    <button class="btn btn-primary fab" (click)="applyProfiling()">Apply</button>

    <div *ngFor="let type of profilers.keys()">
        <h3>{{type}}</h3>
        <tb-listview #listView 
        [items]="profilers.get(type)"
        [filter]="filter.value"
        [showViewButton]="true"
        (onChecked)="onItemChecked($event)"
        (onViewRequest)="onViewRequest($event)"></tb-listview>
    </div>
</div>
<br><br><br><br>