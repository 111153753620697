import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DialogData } from './dialog-data';
import { ToolboxService } from '../../services/toolbox.service';

@Component({
  selector: 'app-literature-browser',
  templateUrl: './literature-browser.component.html',
  styleUrls: ['./literature-browser.component.css']
})

export class LiteratureBrowserComponent {

  constructor(public dialogRef: MatDialogRef<LiteratureBrowserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private toolboxService: ToolboxService) {
      // Show the first category
      this.showCategory(data.categories[0]);
    }

  setElementContent(element: string, content: string, defaultContent: string) {
    if (!content)
      content = defaultContent;
    document.getElementById(element).innerHTML = content;
  }

  showCategory(category: string) {
    this.toolboxService.getLiterature(this.data.profilerGuid, category)
      .subscribe((literature: string) => this.setElementContent('literature', literature, 'No literature'));
  }
}
