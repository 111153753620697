import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { Intercom } from '../../services/intercom.service';
import { IuclidChemical } from '../../models/iuclid-chemical';
import { IuclidDetails } from '../../models/iuclid-details';
import { IuclidId } from '../../models/iuclid-id';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

export interface ResultRow {
  idx: number;
  entity: string;
  entityUrl: string;
  name: string;
  foundIn: string;
  foundInUrl: string;
  database: string;
}

@Component({
  selector: 'tb-iuclidresults',
  templateUrl: './iuclidresults.component.html',
  styleUrls: ['./iuclidresults.component.css']
})
export class IuclidresultsComponent implements AfterViewInit {

  displayedColumns: string[] = ['idx', 'entity', 'name', 'foundIn', 'database'];
  dataSource: MatTableDataSource<ResultRow>;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  _chemicals: IuclidChemical[];
  get chemicals(): IuclidChemical[] {
    return this._chemicals;
  }
  @Input()
  set chemicals(value: IuclidChemical[]) {
    this._chemicals = value;

    var rows: ResultRow[] = [];

    value.forEach((chemical, index) => {
      rows.push(this.chemicalToRow(chemical, index));
    })
    
    this.dataSource = new MatTableDataSource(rows);
    this.dataSource.paginator = this.paginator;
  }

  constructor(private intercom: Intercom) { }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  renameSubstanceToDataset(text: string): string {
    if (text == "Substance")
      text = "Dataset";
    return text;
  }

  chemicalToRow(chemical: IuclidChemical, index: number): ResultRow {
    return {
      idx: index + 1,
      entity: this.getEntityName(chemical.IUCLIDIds[0].Type),
      entityUrl: this.getUrl(chemical),
      name: this.getName(chemical.IUCLIDIds[0]),
      foundIn: this.getEntity(chemical.IUCLIDIds[0]),
      foundInUrl: this.getEntityUrl(chemical.IUCLIDIds[0]),
      database: this.getDatabaseName(chemical.IUCLIDIds[0].SourceId)
    }
  }

  getName(id: IuclidId): string {
    var name = id.IUCLIDName;
    if (!name || name.length === 0)
      name = 'Unnamed';
      return name;
  }

  getEntityName(entity: string) {
    if (entity == "Substance")
      entity = "Dataset";
    return entity;
  }

  getDatabaseName(id: number): string {
    return this.intercom.allIuclidDatabases.filter(function (x) { return x.Id == id }).map(function (x) { return x.Name })[0];
  }

  getUrl(chemical: IuclidChemical): string {
    var url = '';
    if (chemical.IUCLIDIds[0].Type == "Substance")
      url = this.getDocumentUrl(chemical.IUCLIDIds[0].IUCLIDEntityId);
    else if (chemical.IUCLIDIds[0].Type == "Dossier")
      url = this.getDocumentUrl(chemical.IUCLIDIds[0].IUCLIDEntityId, chemical.IUCLIDIds[0].IUCLIDEntityId);
    return url;
  }

  getDocumentUrl(documentGuid: string, dossierGuid: string = "0") {
    return "http://echareach.oasis-lmc.org:8080/iuclid6-web/?key=" + documentGuid + "/" + dossierGuid;
  }

  export() {
    // Prepare export content
    var exportContent = 'QSAR Toolbox - IUCLID Results\n';
    exportContent += '#\t\Entity\tName\tUUID\tDatabase\n';
    this.chemicals.forEach((chemical, idx) => {
      exportContent += idx + '\t' + 
        chemical.IUCLIDIds[0].Type + '\t' + 
        (chemical.IUCLIDIds[0].IUCLIDName ?? 'n/a') + '\t' + 
        chemical.IUCLIDIds[0].IUCLIDEntityId + '\t' + 
        this.getDatabaseName(chemical.IUCLIDIds[0].SourceId) + 
        '\n';
    })

    // Convert to Blob
    var exportBlob = new Blob([exportContent], { type: "text/plain;charset=utf-8" });

    // Initiate file-saver.saveAs to offer file to browser
    saveAs(exportBlob, "IuclidSearchResults.txt");
  }

  getEntity(id: IuclidId): string {
    var details = JSON.parse(id.JSONSearchDetails);
    var rawEntity = details.entity;
    var entity = rawEntity;

    if (rawEntity == "refsubstance")
      entity = "Reference substance";
    else if (rawEntity == "testmaterial")
      entity = "Test material"
    
    return entity;
  }

  getEntityUrl(id: IuclidId): string {
    var details = JSON.parse(id.JSONSearchDetails);
    var documentId = details.id;
    var dossierId = id.Type == "Dossier" ? id.IUCLIDEntityId : "0";
    return this.getDocumentUrl(documentId, dossierId);
  }
}
