/*       QSAR Toolbox Web Client
Service for communicationg with the Toolbox WebAPI */

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, from } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

import { Profiler } from '../models/profiler';
import { Calculator } from '../models/calculator';
import { Simulator } from '../models/simulator';
import { Qsar } from '../models/qsar';
import { Chemical } from '../models/chemical';
import { Calculation } from '../models/calculation';
import { DataPoint } from '../models/data-point';
import { IuclidChemical } from '../models/iuclid-chemical';
import { IuclidDatabase } from '../models/iuclid-database';

import { CookieService } from './cookie.service';

@Injectable()
export class ToolboxService {

  // Options
  webApiUrl: string;
  resultLimit: number = 100;

  constructor(private http: HttpClient, private cookieService: CookieService) {
    //this.webApiUrl = this.getWebApiUrl();
    //this.webApiUrl = 'https://tbdata.oasis-lmc.org/api/v5/';
    //this.webApiUrl = 'https://localhost:5001/api/v5/';
    this.webApiUrl = 'http://tbdata.oasis-lmc.org:8080/api/v5/';
  }

  private executeRequest<T>(url: string): Observable<T> {
    return this.http.get<T>(url).pipe(retry(3), catchError(this.handleError));    
  }

  // Searching

  private findChemicals(url: string, limit: number = this.resultLimit): Observable<string[]> {
    return this.executeRequest<string[]>(url);
    // .then(response => (response.json() as string[]).slice(0, limit))
  }

  findChemicalsByCas(cas: string, limit?: number): Observable<string[]> {
    var url = this.webApiUrl + 'search/cas/' + cas;
    return this.findChemicals(url, limit);
  }

  findChemicalsByName(name: string, option: number, limit?: number): Observable<string[]> {
    var url = this.webApiUrl + 'search/name/' + name + '/' + option;
    return this.findChemicals(url, limit);
  }

  findChemicalsBySmiles(smiles: string, limit?: number): Observable<string[]> {
    var url = this.webApiUrl + 'search/smiles/true/true/?smiles=' + smiles;
    return this.findChemicals(url, limit);
  }

  findChemicalByChemId(chemId: string) {
    var url = this.webApiUrl + "search/chemical/" + chemId;
    return this.executeRequest<Chemical>(url);
  }

  // Profiling

  profilers: Profiler[];

  getProfilers(): Observable<Profiler[]> {
    var url = this.webApiUrl + 'profiling';
    return this.executeRequest<Profiler[]>(url);
  }

  getProfilerCategories(profilerGuid: string): Observable<string[]> {
    var url = this.webApiUrl + 'profiling/' + profilerGuid;
    return this.executeRequest<string[]>(url);
  }

  getProfiles(chemical: string, profiler: string): Observable<string[]> {
    var url = this.webApiUrl + 'profiling/' + profiler + '/' + chemical;
    return this.executeRequest<string[]>(url);
  }

  getLiterature(profilerGuid: string, categoryName: string): Observable<string> {
    var url = this.webApiUrl + 'profiling/' + profilerGuid + '/' + categoryName;
    //return this.executeRequest<string>(url);
    return this.http.get(url, {responseType: 'text'}).pipe(retry(3), catchError(this.handleError));
  }

  // Calculations

  getCalculators(): Observable<Calculator[]> {
    var url = this.webApiUrl + 'calculation';
    return this.executeRequest<Calculator[]>(url);
  }

  calculate(chemical: string, calculator: string): Observable<Calculation> {
    var url = this.webApiUrl + "calculation/" + calculator + "/" + chemical;
    return this.executeRequest<Calculation>(url);
  }

  // Metabolism

  getSimulators(): Observable<Simulator[]> {
    var url = this.webApiUrl + 'metabolism';
    return this.executeRequest<Simulator[]>(url);
  }

  getMetabolites(chemical: string, simulator: string): Observable<string[]> {
    var url = this.webApiUrl + "metabolism/" + simulator + "/" + chemical;
    return this.executeRequest<string[]>(url);
  }

  // Data

  getEndpointTree(): Observable<string[]> {
    var url = this.webApiUrl + "data/endpointTree";
    return this.executeRequest<string[]>(url);
  }

  getEndpoints(position: string): Observable<string[]> {
    var url = this.webApiUrl + "data/endpoint/" + encodeURIComponent(position).replace(/#/g, '%23');
    return this.executeRequest<string[]>(url);
  }

  getData(position: string, endpoint: string, chemical: string): Observable<DataPoint[]> {
    var url = this.webApiUrl + "data/" + encodeURIComponent(position).replace(/#/g, '%23') + "/" + encodeURIComponent(endpoint) + "/" + chemical;
    return this.executeRequest<DataPoint[]>(url);
  }

  // QSARs

  getQsars(position: string): Observable<Qsar[]> {
    var url = this.webApiUrl + "qsar/list/" + encodeURIComponent(position).replace(/#/g, '%23');
    return this.executeRequest<Qsar[]>(url);
  }

  applyQsar(qsar: string, chemical: string): Observable<DataPoint[]> {
    var url = this.webApiUrl + "qsar/apply/" + qsar + "/" + chemical;
    return this.executeRequest<DataPoint[]>(url);
  }

  // IUCLID

  findIuclidChemicalsByCas(cas: string, entity: string, section: string, substanceType: string, sources: string): Observable<IuclidChemical[]> {
    var url = this.webApiUrl + "iuclid/cas/" + cas + "?entity=" + entity + "&section=" + section + "&substanceType=" + substanceType + "&sources=" + sources;
    return this.executeRequest<IuclidChemical[]>(url);
  }

  findIuclidChemicalsByEcNumber(ecnumber: string, entity: string, section: string, substanceType: string, sources: string): Observable<IuclidChemical[]> {
    var url = this.webApiUrl + "iuclid/ecnumber/" + ecnumber + "?entity=" + entity + "&section=" + section + "&substanceType=" + substanceType + "&sources=" + sources;
    return this.executeRequest<IuclidChemical[]>(url);
  }

  findIuclidChemicalsByName(name: string, entity: string, section: string, substanceType: string, sources: string): Observable<IuclidChemical[]> {
    var url = this.webApiUrl + "iuclid/name/?name=" + name + "&entity=" + entity + "&section=" + section + "&substanceType=" + substanceType + "&sources=" + sources;
    return this.executeRequest<IuclidChemical[]>(url);
  }

  findIuclidChemicalsBySmiles(smiles: string, isFragment: boolean, entity: string, section: string, substanceType: string, sources: string): Observable<IuclidChemical[]> {
    var url = this.webApiUrl + "iuclid/smiles/" + isFragment + "?smiles=" + encodeURIComponent(smiles) + "&entity=" + entity + "&section=" + section + "&substanceType=" + substanceType + "&sources=" + sources;
    return this.executeRequest<IuclidChemical[]>(url);
  }

  getAllDatabases(): Observable<IuclidDatabase[]> {
    var url = this.webApiUrl + "iuclid/databases";
    return this.executeRequest<IuclidDatabase[]>(url);
  }

  // Working with structures

  getConnectivityBySmiles(smiles: string): Observable<string> {
    var url = this.webApiUrl + "structure/connectivity?smiles=" + smiles;
    return this.executeRequest<string>(url);
  }

  // getConnectivityByChemId(chemId: string) {
  //   // TODO: Implement some kind of cache
  //   return this.findChemicalByChemId(chemId).
  //     then(chemical => this.getConnectivityBySmiles(chemical.Smiles));
  // }

  // Handling errors

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };

  // Working with cookies
  
  saveWebApiUrl(url: string) {
    this.cookieService.createCookie("WebAPI-4.4", url, 30);
  }

  getWebApiUrl(): string {
    return this.cookieService.readCookie("WebAPI-4.4");
  }

  setUrl(url: string) {
    this.webApiUrl = url;
    this.saveWebApiUrl(url);
  }

}