<a *ngIf="chemical != undefined" href="javascript:;" class="list-group-item list-group-item-action">
    <div class="row">
        <div class="col" id="{{divName}}">
            <strong *ngIf="!chemical.Smiles">No structure available</strong>
        </div>
        <div class="col">
            <div>
                <strong>CAS#: </strong>{{chemical.Cas}} </div>
            <div>
                <strong>SMILES: </strong>{{chemical.Smiles}}</div>
            <div class="names">
                <strong>Name(s): </strong>
                <ul>
                    <li *ngFor="let name of chemical.Names">
                        {{ name }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</a>