import { Component, OnInit, Input } from '@angular/core';

import { ToolboxService } from '../../services/toolbox.service'
import { Intercom } from '../../services/intercom.service';
import { Chemical } from '../../models/chemical';
import { ProfilingResults, CalculationResults, MetabolismResults, DataResults, QsarResults } from '../../models/results';


@Component({
  selector: 'tb-targetview',
  templateUrl: './targetview.component.html',
  styleUrls: ['./targetview.component.css']
})
export class TargetviewComponent implements OnInit {

  @Input() summaryMode: boolean = false;

  selectedChemical: Chemical;
  profilingResults: ProfilingResults;
  calculationResults: CalculationResults;
  metabolismResults: MetabolismResults;
  dataResults: DataResults;
  qsarResults: QsarResults;

  color = 'primary';
  mode = 'query';
  value = 50;
  isLoading = this.intercom.isLoading;

  constructor(private toolboxService: ToolboxService, private intercom: Intercom) {
    intercom.chemicalSelected$.subscribe(
      chemical => this.updateChemical(chemical));

    intercom.chemicalProfiled$.subscribe(
      results => this.updateProfiling(results));

    intercom.chemicalCalculated$.subscribe(
      results => this.updateCalculations(results));

    intercom.chemicalMetabolized$.subscribe(
      results => this.updateMetabolites(results));

    intercom.chemicalData$.subscribe(
      results => this.updateData(results));

    intercom.chemicalQsar$.subscribe(
      results => this.updateQsars(results));
  }

  ngOnInit() {
    this.selectedChemical = this.intercom.selectedChemical;
    this.profilingResults = this.intercom.profilingResults;
    this.calculationResults = this.intercom.calculationResults;
    this.metabolismResults = this.intercom.metabolismResults;
    this.dataResults = this.intercom.dataResults;
    this.qsarResults = this.intercom.dataResults;
  }

  updateChemical(chemical: Chemical) {
    this.selectedChemical = chemical;
    this.clearResults();
  }

  clearResults() {
    this.profilingResults = undefined;
    this.calculationResults = undefined;
    this.metabolismResults = undefined;
    this.dataResults = undefined;
    this.qsarResults = undefined;
  }

  updateProfiling(results: ProfilingResults) {
    this.profilingResults = results;
  }

  updateCalculations(results: CalculationResults) {
    this.calculationResults = results;
  }

  updateMetabolites(results: MetabolismResults) {
    this.metabolismResults = results;
  }

  updateData(results: DataResults) {
    this.dataResults = results;
  }

  updateQsars(results: QsarResults) {
    this.qsarResults = results;
  }  

  modalTitle: string;

  showLiterature(profiler: string, profile: string) {
    this.modalTitle = profile;

    // Retrieve the GUID of the selected profiler
    var profilerGuid = this.intercom.getProfilerGuidByCaption(profiler);

    this.toolboxService.getLiterature(profilerGuid, profile)
      .subscribe((literature: string) => this.setElementContent('modalContent', literature, 'No literature'));
    
    this.showModal();
  }

  setElementContent(element: string, content: string, defaultContent: string) {
    if (!content)
      content = defaultContent;
    document.getElementById(element).innerHTML = content;
  }

  showMetabolite(smiles: string) {
    this.modalTitle = smiles;
    this.toolboxService.getConnectivityBySmiles(smiles)
      .subscribe((connectivity: string) => drawJsonMolecule(connectivity, '#modalContent'));

    this.showModal();
  }

  private showModal() {
    // Show the modal by clicking on the invisible button
    document.getElementById('modalButton').click();
  }

}