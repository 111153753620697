import { EndpointTreeItem } from "./endpoint-tree-item";

export class EndpointTree {
    root: EndpointTreeItem;

    constructor(list: string[]) {
        this.root = new EndpointTreeItem("Root");
        this.parseList(list);
    }

    parseList(list: string[]) {
        for (let line of list) {
            var items = line.split('#');

            var parent = this.root.getOrCreateChild(items[0]);

            for (let item of items.slice(1)) {
                parent = parent.getOrCreateChild(item);
            }
        }
    }

    dumpToList(): EndpointTreeItem[] {
        return this.root.children;
    }
}