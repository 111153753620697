import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

// Material
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';

// Core UI
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';

// Components
import { ChemviewComponent } from './components/chemview/chemview.component';
import { TargetviewComponent } from './components/targetview/targetview.component';
import { ListviewComponent } from './components/listview/listview.component';
import { FilterComponent } from './components/filter/filter.component';

// Services
import { ToolboxService } from './services/toolbox.service';
import { CookieService } from './services/cookie.service';
import { ExportService } from './services/export.service';
import { Intercom } from './services/intercom.service';

// Interceptors
import { LoaderInterceptor } from './interceptors/loader.interceptor';

// Sections
import { InputComponent } from './sections/input/input.component';
import { ProfilingComponent } from './sections/profiling/profiling.component';
import { CalculationsComponent } from './sections/calculations/calculations.component';
import { MetabolismComponent } from './sections/metabolism/metabolism.component';
import { SummaryComponent } from './sections/summary/summary.component';
import { DataComponent } from './sections/data/data.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { QsarComponent } from './sections/qsar/qsar.component';
import { LiteratureBrowserComponent } from './dialogs/literature-browser/literature-browser.component';
import { IuclidComponent } from './sections/iuclid/iuclid.component';
import { IuclidscopeComponent } from './components/iuclidscope/iuclidscope.component';
import { IuclidresultsComponent } from './components/iuclidresults/iuclidresults.component';

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatTreeModule,
    MatIconModule,
    MatListModule,
    MatDialogModule,
    MatProgressBarModule,
    MatCheckboxModule,
    MatCardModule,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule
  ],
  declarations: [
    AppComponent,
    ChemviewComponent,
    TargetviewComponent,
    ListviewComponent,
    FilterComponent,
    InputComponent,
    ProfilingComponent,
    CalculationsComponent,
    MetabolismComponent,
    SummaryComponent,
    DataComponent,
    QsarComponent,
    LiteratureBrowserComponent,
    IuclidComponent,
    IuclidscopeComponent,
    IuclidresultsComponent
  ],
  bootstrap: [AppComponent],
  providers: [
    ToolboxService,
    CookieService,
    ExportService,
    Intercom,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true }
  ]
})
export class AppModule { }
