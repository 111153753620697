<h2>Data</h2>

<div class="input-group col-md-12">
    <div class="input-group-prepend">
        <span class="input-group-text">Selected position: </span>
    </div>

    <input type="text" class="form-control" #position id="position" [value]="selectedPosition" readonly>

    <div class="input-group-append">
        <a class="btn btn-primary" href="#" data-toggle="modal" data-target="#exampleModalLong">
            <i class="far fa-hand-point-up"></i>
        </a>
    </div>
</div>
<div class="input-group col-md-12">
    <div class="input-group-prepend">
        <span class="input-group-text">Selected endpoint: </span>
    </div>

    <select class="form-control" #endpoint size="1" (change)="selectEndpoint(endpoint.value)">
        <option></option>
        <option *ngFor="let endpoint of endpoints" [value]="endpoint">{{endpoint}}</option>
    </select>

    <div class="input-group-append">
        <button class="btn btn-primary" (click)="getData()">Gather data</button>
    </div>
</div>



<table *ngIf="retrievedData != undefined" class="table table-striped">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Value</th>
      <!-- <th scope="col">MinValue</th>
      <th scope="col">MaxValue</th> -->
      <th scope="col">Qualifier</th>
      <!-- <th scope="col">MinQualifier</th>
      <th scope="col">MaxQualifier</th> -->
      <th scope="col">DataType</th>
      <th scope="col">Unit</th>
      <th scope="col">Family</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let datapoint of retrievedData; let i = index">
      <th scope="row">{{i + 1}}</th>
      <td>{{datapoint.Value}}</td>
      <!-- <td>{{datapoint.MinValue}}</td>
      <td>{{datapoint.MaxValue}}</td> -->
      <td>{{datapoint.Qualifier}}</td>
      <!-- <td>{{datapoint.MinQualifier}}</td>
      <td>{{datapoint.MaxQualifier}}</td> -->
      <td>{{datapoint.DataType}}</td>
      <td>{{datapoint.Unit}}</td>
      <td>{{datapoint.Family}}</td>
    </tr>
  </tbody>
</table>


<div class="modal fade" id="exampleModalLong" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Endpoint tree</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
          
          <!-- Leaves -->
          <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
            <button mat-icon-button disabled></button>
            <mat-icon class="type-icon" [attr.aria-label]="node.type + 'icon'">
              {{ node.expandable ? 'folder' : 'description' }}
            </mat-icon>
            <button type="button"  class="btn btn-outline-secondary" (click)="selectPosition(node.path)" data-dismiss="modal"> {{node.name}} </button>
          </mat-tree-node>
        
          <!-- Parents -->
          <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
            <button mat-icon-button matTreeNodeToggle
                    [attr.aria-label]="'toggle ' + node.name">
              <mat-icon class="mat-icon-rtl-mirror">
                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
              </mat-icon>
            </button>
            <!-- <mat-icon class="type-icon" [attr.aria-label]="node.type + 'icon'">
              {{ node.expandable ? 'folder' : 'description' }}
            </mat-icon> -->
            {{node.name}}
          </mat-tree-node>
        </mat-tree>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>