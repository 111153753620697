import { Component, ViewChild, ElementRef, Renderer2 } from '@angular/core';

import { Chemical } from '../../models/chemical';
import { ToolboxService } from '../../services/toolbox.service';
import { Intercom } from '../../services/intercom.service';

@Component({
    selector: 'tb-section-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.css']
})

export class InputComponent {

    lastSearch: string;
    searchLimit: number = 20;
    shownChemicals: number;
    editorSmiles: string = '';

    private _chemIds: string[];
    get chemIds(): string[] {
        return this._chemIds;
    }
    set chemIds(value: string[]) {
        this._chemIds = value;
        this.shownChemicals = this.chemIds.length < this.searchLimit ? this.chemIds.length : this.searchLimit;
    }

    stopListening: Function;

    constructor(private toolboxService: ToolboxService, private intercom: Intercom, private renderer: Renderer2) {
        this.stopListening =
            renderer.listen('window', 'message', this.handleMessage.bind(this));
    }

    handleMessage(event: Event) {
        const message = event as MessageEvent;

        // if (message.origin !== 'http://storage.oasis-lmc.org')
        //     return;

        this.editorSmiles = message.data;
    }

    findChemicalsByCas(cas: string): void {
        cas = cas.trim();
        if (!cas) return;

        this.lastSearch = "CAS is " + cas;
        this.toolboxService.findChemicalsByCas(cas).subscribe(
            (data: string[]) => this.chemIds = data
        );
    }

    findChemicalsByName(name: string, option: number): void {
        name = name.trim();
        if (!name) return;
        this.lastSearch = "Name " + NameOperators[option] + " " + name;
        this.toolboxService.findChemicalsByName(name, option).subscribe(
            (data: string[]) => this.chemIds = data
        );
    }

    findChemicalsBySmiles(smiles: string): void {
        smiles = smiles.trim();
        if (!smiles) return;

        this.lastSearch = "SMILES is " + smiles;
        this.toolboxService.findChemicalsBySmiles(smiles).subscribe(
            (data: string[]) => this.chemIds = data
        );
    }

    clearResults() {
        this.chemIds = [];
    }

    selectChemical(chemical: Chemical) {
        this.intercom.setChemical(chemical);
        window.scrollTo(0, 0);
    }

    @ViewChild('iframe') iframe: ElementRef;
    showCurrentSmiles() {
        this.iframe.nativeElement.contentWindow.postMessage('I want the smiles!', '*');
    }

    encode(smiles: string): string {
        let encoded = encodeURIComponent(smiles);
        return encoded;
    }
}

enum NameOperators {
    Is,
    StartsWith,
    Contains
}