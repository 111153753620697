<!-- First row -->
<div class="row">
    
    <!-- Database -->
    <div class="col-6">        
        <mat-card>            
            <mat-card-header>
                <mat-card-title>Database</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <mat-checkbox *ngFor="let database of databases; index as i" id="db{{i}}" [(ngModel)]="database.IsSelected" color="primary">{{database.Name}}</mat-checkbox>
            </mat-card-content>
        </mat-card>
    </div>
    <!-- Database -->

    <!--Entity-->
    <div class="col-6">
        <mat-card>
            <mat-card-header>
                <mat-card-title>Entity</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <mat-checkbox [(ngModel)]="entity_dataset" color="primary">Dataset</mat-checkbox><br>
                <mat-checkbox [(ngModel)]="entity_dossier" color="primary">Dossier</mat-checkbox>
            </mat-card-content>
        </mat-card>
    </div>
    <!-- Entity -->

</div>

<!-- Second row -->
<div class="row mt-1">

    <!-- Substance type -->
    <div class="col-6">
        <mat-card>
            <mat-card-header>
                <mat-card-title>Substance type</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <mat-checkbox [(ngModel)]="type_mono" color="primary">Monoconstituent</mat-checkbox><br>
                <mat-checkbox [(ngModel)]="type_multi" color="primary">Multiconstituent</mat-checkbox><br>
                <mat-checkbox [(ngModel)]="type_poly" color="primary">Polymer</mat-checkbox><br>
                <mat-checkbox [(ngModel)]="type_uvcb" color="primary">UVCB</mat-checkbox><br>
                <mat-checkbox [(ngModel)]="type_micro" color="primary">Microorganism</mat-checkbox><br>
                <mat-checkbox [(ngModel)]="type_na" color="primary">Not specified</mat-checkbox>                                
            </mat-card-content>
        </mat-card>
    </div>
    <!-- Substance type -->

    <!--Section-->
    <div class="col-6">
        <mat-card>
            <mat-card-header>
                <mat-card-title>Section</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <!-- <mat-list>
                    <div mat-subheader>Identification</div>
                    <mat-list-item>
                        <mat-checkbox [(ngModel)]="se_representative" color="primary">Representative substance</mat-checkbox>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                    <div mat-subheader>Composition</div>
                    <mat-list-item>
                        <mat-checkbox [(ngModel)]="se_constituent" color="primary">Constituent</mat-checkbox>
                    </mat-list-item>
                    <mat-list-item>
                        <mat-checkbox [(ngModel)]="se_impurity" color="primary">Impurity</mat-checkbox>                  
                    </mat-list-item>
                    <mat-list-item>
                        <mat-checkbox [(ngModel)]="se_additive" color="primary">Additive</mat-checkbox>
                    </mat-list-item>
                    <div mat-subheader>Endpoint study records</div>
                    <mat-list-item>
                        <mat-checkbox [(ngModel)]="se_testmaterial" color="primary">Test material</mat-checkbox>
                    </mat-list-item>
                </mat-list> -->
                
                <u>Identification:</u><br>
                <mat-checkbox [(ngModel)]="se_representative" color="primary">Representative substance</mat-checkbox><br>
                <u>Composition:</u><br>
                <mat-checkbox [(ngModel)]="se_constituent" color="primary">Constituent</mat-checkbox><br>
                <mat-checkbox [(ngModel)]="se_impurity" color="primary">Impurity</mat-checkbox><br>
                <mat-checkbox [(ngModel)]="se_additive" color="primary">Additive</mat-checkbox><br>
                <u>Endpoint study records:</u><br>
                <mat-checkbox [(ngModel)]="se_testmaterial" color="primary">Test material</mat-checkbox>
            </mat-card-content>
        </mat-card>
    </div>
    <!--Section-->
    
</div>