<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand" href="#">
    <img src="/assets/images/toolbox_45.gif" width="30" height="30" class="d-inline-block" alt="">QSAR Toolbox</a>
  
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
    aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  
  <div *ngIf="currentRoute() != '/iuclid'" class="collapse navbar-collapse" id="navbarNav">
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" routerLink="/input" routerLinkActive="active">Input</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/profiling" routerLinkActive="active">Profiling</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/calculations" routerLinkActive="active">Calculations</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/metabolism" routerLinkActive="active">Metabolism</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/data" routerLinkActive="active">Data</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/qsar" routerLinkActive="active">QSAR</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/summary" routerLinkActive="active">Summary</a>
      </li>
    </ul>
  </div>

  <button *ngIf="currentRoute() != '/iuclid'" type="button" class="btn btn-danger" routerLink="/iuclid">IUCLID</button>
  <!-- <button type="button" class="btn btn-secondary" data-toggle="modal" data-target="#optionsDialog">Options</button> -->
</nav>

<!-- Options dialog (modal) -->
<div class="modal" id="optionsDialog" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <label for="input">WebAPI URL: </label>
        <input #input id="input" type="text" size="40" [value]="webApiUrl">
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-success" data-dismiss="modal" (click)="saveurl(input.value)">Save</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>        
      </div>
    </div>
  </div>
</div>

<div class="container-fluid">

  <div class="row">
    <!-- The target view is only visible on sizes >md on all pages but /summary -->
    <div *ngIf="currentRoute() != '/summary' && currentRoute() != '/iuclid'" class="col-md-6 d-none d-md-block">
      <h2>Target</h2>
      <tb-targetview></tb-targetview>
    </div>

    <!-- the current section takes half of the page when not in summary mode -->
    <div *ngIf="currentRoute() != '/summary' && currentRoute() != '/iuclid'; else wholePage" class="col-md-6">
      <router-outlet></router-outlet>
    </div>

    <ng-template #wholePage>
      <div class="col-md-11">
        <router-outlet></router-outlet>
      </div>
    </ng-template>
  </div>

</div>