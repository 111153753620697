import { Component, OnInit } from '@angular/core';

import { Calculator } from '../../models/calculator';
import { Calculation } from '../../models/calculation';
import { CalculationResults } from '../../models/results';
import { ListEvent } from '../../components/listview/listevent';
import { ToolboxService } from '../../services/toolbox.service';
import { Intercom } from '../../services/intercom.service';

@Component({
  selector: 'tb-section-calculations',
  templateUrl: './calculations.component.html',
  styleUrls: ['./calculations.component.css']
})

export class CalculationsComponent implements OnInit {
  
  // Holds all available calculators, grouped by type
  calculators: Map<string, Calculator[]>;

  // Holds the calculators, selected by the user
  selected: string[] = [];

  // Holds the calculation results
  results: CalculationResults = new Map<string, string>();

  private _calculators: Calculator[];

  constructor(private toolboxService: ToolboxService, private intercom: Intercom) { }

  ngOnInit() {
    // Retrieve all available calculators
    this.getCalculators();
  }

  getCalculators(): void {
    this.toolboxService.getCalculators().subscribe(
      (data: Calculator[]) => this.calculators = this.groupCalculators(data)
    );
    //.then(calculators => this.calculators = this.groupCalculators(calculators));
  }

  groupCalculators(calculators: Calculator[]): Map<string, Calculator[]> {

    // save a reference to the the original list
    this._calculators = calculators;

    let groupedCalculators = new Map<string, Calculator[]>();

    // Sort the calculators before grouping
    calculators.sort((x1, x2) => +x1.Is3D - +x2.Is3D);

    for (let calculator of calculators) {

      // Filter hidden calculators
      //if (!calculator.IsVisible)
      // if (calculator.Caption.startsWith('Category') || calculator.Caption == 'ERB')
      //     continue;

      let type = calculator.Is3D ? "3D" : "2D";

      // add a new empty array if type does not exist yet
      if (!groupedCalculators.has(type)) {
        let list: Calculator[] = [];
        groupedCalculators.set(type, list);
      }

      // add the profiler to the array associated to its type
      groupedCalculators.get(type).push(calculator);
    }

    // Sort each sublist separately...
    groupedCalculators.get("2D").sort(function (a, b) {
      if (a.Caption < b.Caption) return -1;
      if (a.Caption > b.Caption) return 1;
      return 0;
    });

    groupedCalculators.get("3D").sort(function (a, b) {
      if (a.Caption < b.Caption) return -1;
      if (a.Caption > b.Caption) return 1;
      return 0;
    });

    return groupedCalculators;
  }

  // Stores each selected calculator in the [selected] array
  onItemChecked(event: ListEvent) {
    if (event.Checked)
      this.selected.push(event.Guid)
    else {
      var index = this.selected.indexOf(event.Guid, 0);
      if (index > -1) {
        this.selected.splice(index, 1);
      }
    }
  }

  // Handles the Calculate action by querying the ToolboxService for each parameter and then passing the results to receiveResults
  calculate() {
    if (this.selected.length == 0) {
      window.alert("No calculators selected");
      return;
    }

    if (this.intercom.selectedChemical == undefined) {
      window.alert("No target chemical");
      return;
    }

    this.results.clear();
    for (let calculator of this.selected) {
      this.toolboxService.calculate(this.intercom.selectedChemical.ChemId, calculator).subscribe(
        (calculation: Calculation) => this.receiveResults(calculator, calculation)
      );
      //.then(calculation => this.receiveResults(calculator, calculation));
    }
    // Go to the top of the page
    window.scrollTo(0, 0);
  }

  // Retrieves a calculator's name from its GUID
  getCalculatorName(calculatorGuid: string): string {
    let result: string = "";
    for (let calculator of this._calculators) {
      if (calculator.Guid == calculatorGuid) {
        return calculator.Caption;
      }
    }
    return result;
  }

  // Handles the calculation results from the toolboxService
  receiveResults(calculatorGuid: string, calculation: Calculation) {
    this.results.set(this.getCalculatorName(calculatorGuid), calculation.Value + " " + calculation.Unit);
    this.intercom.setCalculationResults(this.results);
  }

}
