<h2>Calculations</h2>

<div *ngIf="calculators != undefined">
    <tb-filter #filter></tb-filter>
    <br>
    
    <button class="btn btn-primary fab" (click)="calculate()">Calculate</button>

    <div *ngFor="let type of calculators.keys()">
        <h3>{{type}}</h3>
        <tb-listview #listView [items]="calculators.get(type)" [filter]="filter.value" (onChecked)="onItemChecked($event)"></tb-listview>
    </div>
</div>

<br><br><br><br>