export class EndpointTreeItem {
    caption: string;
    path: string;
    children?: EndpointTreeItem[];

    constructor(text: string, parentPath: string = "") {
        this.caption = text;
        this.children = [];
        var pathStart = (parentPath == "" || parentPath == "Root") ? "" : parentPath + '#';
        this.path = pathStart + text;
    }

    private addChild(caption: string): EndpointTreeItem {
        var node = new EndpointTreeItem(caption, this.path);
        this.children.push(node);
        return node;
    }

    getOrCreateChild(caption: string): EndpointTreeItem {
        // search direct children to find an item with the same caption
        for (let node of this.children) {
            if (node.caption == caption)
                return node;
        }
        // if none found - create a new one
        return this.addChild(caption);
    }
}
