import { Component, ViewChild, ElementRef, Renderer2, OnInit, HostListener } from '@angular/core';

import { ToolboxService } from '../../services/toolbox.service';
import { Intercom } from '../../services/intercom.service';
import { IuclidChemical } from '../../models/iuclid-chemical';
import { IuclidscopeComponent } from '../../components/iuclidscope/iuclidscope.component';
import { IuclidDatabase } from '../../models/iuclid-database';

@Component({
  selector: 'app-tb-section-iuclid',
  templateUrl: './iuclid.component.html',
  styleUrls: ['./iuclid.component.css']
})
export class IuclidComponent implements OnInit {

  // For direct access to the IuclidScopeComponent methods and properties
  @ViewChild(IuclidscopeComponent)
  private iuclidScope: IuclidscopeComponent;

  // For direct access to the iframe methods and properties
  @ViewChild('iframe')
  private iframe: ElementRef;

  databases: IuclidDatabase[];

  lastSearch: string;
  searchLimit: number = 20;
  shownChemicals: number;
  editorSmiles: string = '';
  resultsVisible = false;
  searchInProgress = false;
  errorMessage = '';

  smiles_option = "Fragment";

  private _chemicals: IuclidChemical[];
  get chemicals(): IuclidChemical[] {
    return this._chemicals;
  }
  set chemicals(value: IuclidChemical[]) {
    this._chemicals = value;
    //this.showChemicals
    this.searchInProgress = false;
    this.resultsVisible = (value !== null);
    this.errorMessage = '';
  }

  constructor(private toolboxService: ToolboxService, private intercom: Intercom, private renderer: Renderer2) { }

  ngOnInit(): void {
    this.toolboxService.getAllDatabases().subscribe(
      (data: IuclidDatabase[]) => {
        this.intercom.allIuclidDatabases = data;
        this.databases = data;
      }
    );
  }

  // Requests the smiles from the 2D editor iframe
  showCurrentSmiles() {
    this.iframe.nativeElement.contentWindow.postMessage('I want the smiles!', '*');
  }

  // Listens to responses from the 2D editor iframe
  @HostListener('window:message', ['$event'])
  onPostMessage(event) {
    var trustedOrigin = 'http://storage.oasis-lmc.org';
    if (event.origin != trustedOrigin)
      return;
    this.editorSmiles = event.data;
  }

  findChemicalsByCas(cas: string): void {
    this.clearResults();

    // Remove non-digits, trim
    cas = cas.replace(/\D/g,'');
    cas = cas.trim();

    if (!cas) return;

    this.lastSearch = "CAS is " + cas;

    // Retrieve scope
    var sources = this.iuclidScope.getDatabases();    
    var entity = this.iuclidScope.getEntity();
    var section = this.iuclidScope.getSection();
    var substanceType = this.iuclidScope.getSubstanceType();

    this.searchInProgress = true;
    this.toolboxService.findIuclidChemicalsByCas(cas, entity, section, substanceType, sources).subscribe(
      (data: IuclidChemical[]) => this.chemicals = data,
      error => this.handleError(error)
    );
  }

  findChemicalsByEcNumber(ecnumber: string): void {
    this.clearResults();

    // Remove non-digits, trim
    ecnumber = ecnumber.replace(/\D/g,'');
    ecnumber = ecnumber.trim();
    
    if (!ecnumber) return;

    this.lastSearch = "EC Number is " + ecnumber;

    // Retrieve scope
    var sources = this.iuclidScope.getDatabases();    
    var entity = this.iuclidScope.getEntity();
    var section = this.iuclidScope.getSection();
    var substanceType = this.iuclidScope.getSubstanceType();

    this.searchInProgress = true;
    this.toolboxService.findIuclidChemicalsByEcNumber(ecnumber, entity, section, substanceType, sources).subscribe(
      (data: IuclidChemical[]) => this.chemicals = data,
      error => this.handleError(error)
    );
  }

  findChemicalsByName(name: string): void {
    this.clearResults();

    name = name.trim();
    if (!name) return;
    this.lastSearch = "Name is " + name;

    // Retrieve scope
    var sources = this.iuclidScope.getDatabases();    
    var entity = this.iuclidScope.getEntity();
    var section = this.iuclidScope.getSection();
    var substanceType = this.iuclidScope.getSubstanceType();

    this.searchInProgress = true;
    this.toolboxService.findIuclidChemicalsByName(name, entity, section, substanceType, sources).subscribe(
      (data: IuclidChemical[]) => this.chemicals = data,
      error => this.handleError(error)
    );
  }

  findChemicalsBySmiles(smiles: string): void {
    this.clearResults();

    smiles = smiles.trim();
    if (!smiles) return;

    this.lastSearch = "SMILES is " + smiles;

    // Retrieve scope
    var sources = this.iuclidScope.getDatabases();
    var entity = this.iuclidScope.getEntity();
    var section = this.iuclidScope.getSection();
    var substanceType = this.iuclidScope.getSubstanceType();
    
    var isFragment = this.smiles_option == "Fragment";

    this.searchInProgress = true;
    this.toolboxService.findIuclidChemicalsBySmiles(smiles, isFragment, entity, section, substanceType, sources).subscribe(
      (data: IuclidChemical[]) => this.chemicals = data,
      error => this.handleError(error)
    );
  }

  handleError(error) {
    this.searchInProgress = false;
    this.errorMessage = error;
    console.log('WebAPI error: ', error);
  }

  clearResults() {
    this.chemicals = null;
  }

  encode(smiles: string): string {
    let encoded = encodeURIComponent(smiles);
    return encoded;
  }

  getEditorType(): string {
    var isFragment = this.smiles_option == "Fragment";
    if (isFragment)
      return 'smart';
    return 'smiles'; 
  }

  getRandomString(): string {
    return Math.random().toString(36).substr(2, 5);
  }

  selectAllOptions() {
    this.iuclidScope.selectAll();
  }

}

enum NameOperators {
  Is,
  StartsWith,
  Contains
}
