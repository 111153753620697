import { Component, Input, Output } from '@angular/core';

import { ToolboxService } from '../../services/toolbox.service'
import { Chemical } from '../../models/chemical'

@Component({
  selector: 'tb-chemview',
  templateUrl: './chemview.component.html',
  styleUrls: ['./chemview.component.css']
})

export class ChemviewComponent {

  _chemId: string;
  divName: string;

  @Input()
  
  set chemId(chemId: string) {
    this._chemId = chemId;
    this.divName = "div-" + chemId;
    this.drawChemical();
  }
  
  get chemId(): string {
    return this._chemId;
  }

  @Output() chemical: Chemical;

  constructor(private toolboxService: ToolboxService) { }

  drawChemical() {
    this.toolboxService.findChemicalByChemId(this.chemId)
      .subscribe((data: Chemical) => this.drawSmiles(data));
  }

  drawSmiles(chemical: Chemical) {
    this.chemical = chemical;
    if (!chemical.Smiles) {
      this.drawConnectivity("");
      return;
    }
    this.toolboxService.getConnectivityBySmiles(chemical.Smiles)
      .subscribe((data: string) => this.drawConnectivity(data));
  }

  drawConnectivity(connectivity: string) {
    this.chemical.Connectivity = connectivity;
    drawJsonMolecule(connectivity, "#" + this.divName)
  }

}