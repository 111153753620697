import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";

import { Intercom } from '../services/intercom.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

    constructor(public intercom: Intercom) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.intercom.show();
        return next.handle(req).pipe(
            finalize(() => this.intercom.hide())
        );
    }
}