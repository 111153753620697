<div>
    <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" matSort>
    
            <ng-container matColumnDef="idx">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> # </th>
                <td mat-cell *matCellDef="let row"> {{row.idx}} </td>
            </ng-container>
    
            <ng-container matColumnDef="entity">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Entity </th>
                <td mat-cell *matCellDef="let row"> {{row.entity}} </td>
            </ng-container>
    
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                <td mat-cell *matCellDef="let row"> <a href="{{row.entityUrl}}" target="_blank">{{row.name}}</a> </td>
            </ng-container>
    
            <ng-container matColumnDef="foundIn">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Found In </th>
                <td mat-cell *matCellDef="let row"> <a href="{{row.foundInUrl}}" target="_blank"> {{row.foundIn}} </a> </td>
            </ng-container>
    
            <ng-container matColumnDef="database">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Database </th>
                <td mat-cell *matCellDef="let row"> {{row.database}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
        </table>
    
        <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
    </div>

    <br>
    <!-- Button panel-->
    <div class="d-flex justify-content-end">
        <button mat-stroked-button color="primary" (click)="export()">Export list</button>
        <!-- <button mat-stroked-button disabled>View details</button> -->
    </div>
</div>