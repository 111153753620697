
  <div *ngFor="let item of items" class="input-group">
    
    <div class="input-group-prepend">
      <div class="input-group-text">
        
        <input #cb type="checkbox" 
        [checked]="selected.indexOf(item.Guid) > -1" 
        (change)="onChange(item.Guid, $event.target.checked)">
        
      </div>
    </div>

    <input type="text" class="form-control" readonly
    [value]="item.Caption" 
    (click)="toggle(item.Guid)">

    <div *ngIf="showViewButton" class="input-group-append">
      <button class="btn btn-light" type="button" (click)="onView(item.Guid)">
        <i class="fas fa-eye"></i>
      </button>
    </div>

  </div>
