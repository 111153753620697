import { Component } from '@angular/core';
import { saveAs } from 'file-saver';

import { ExportService } from '../../services/export.service';

@Component({
  selector: 'tb-section-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.css']
})

export class SummaryComponent {

  constructor(private exportService: ExportService) { }

  print() {
    window.print();
  }

  export() {     
    var exportContent = this.exportService.generateExport();   
    var exportBlob = new Blob([exportContent], {type: "text/plain;charset=utf-8"});
    saveAs(exportBlob, "ToolboxWebExport.txt");
  }

}
