<h1 mat-dialog-title>{{data.profilerName}}</h1>
<div class="container">
    <div class="row">
      <div class="col">
        <mat-action-list>
            <button mat-list-item *ngFor="let category of data.categories" 
            (click)="showCategory(category)"> {{ category }} </button>
        </mat-action-list>
      </div>
      <div class="col" id="literature">
      </div>
    </div>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Close</button>
  </div>




