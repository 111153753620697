import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ToolboxObject } from '../../models/toolbox-object';
import { ListEvent } from './listevent';

@Component({
  selector: 'tb-listview',
  templateUrl: './listview.component.html',
  styleUrls: ['./listview.component.css']
})

export class ListviewComponent {

  private _items: ToolboxObject[];
  private _filteredItems: ToolboxObject[];
  private _filter: string = '';
  selected: string[] = [];

  @Input() set filter(filter: string) {
    this._filter = filter.toLowerCase();
    this.filterItems();
  }

  @Input() set items(items: ToolboxObject[]) {
    this._items = items;
    this.filterItems();
  }
  get items() {
    return this._filteredItems;
  }

  @Input() showViewButton: Boolean;

  @Output() onChecked = new EventEmitter<ListEvent>();

  @Output() onViewRequest = new EventEmitter<ListEvent>();

  constructor() { }

  onChange(guid: string, checked: boolean) {
    this.onChecked.emit(new ListEvent(guid, checked));

    if (checked)
      this.selected.push(guid)
    else {
      var index = this.selected.indexOf(guid, 0);
      if (index > -1) {
        this.selected.splice(index, 1);
      }
    }
  }

  onView(guid: string) {
    this.onViewRequest.emit(new ListEvent(guid, null));
  }

  toggle(guid: string) {
    this.onChange(guid, !this.isChecked(guid));
  }

  isChecked(guid: string): boolean {
    return this.selected.indexOf(guid) > -1;
  }

  filterItems() {
    this._filteredItems = this._items;
    if (this._items == undefined || !this._filter) return;
    this._filteredItems = this._items.filter(item => item.Caption.toLowerCase().indexOf(this._filter) >= 0);
  }

}