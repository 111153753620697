import { Component, Input } from '@angular/core';
import { IuclidDatabase } from '../../models/iuclid-database';

@Component({
  selector: 'tb-iuclidscope',
  templateUrl: './iuclidscope.component.html',
  styleUrls: ['./iuclidscope.component.css']
})
export class IuclidscopeComponent {

  // Database
  _databases: IuclidDatabase[];
  get databases(): IuclidDatabase[] {
    return this._databases;
  }
  @Input()
  set databases(value: IuclidDatabase[]) {
    this._databases = value;
    if (this._databases != undefined)
      this.selectAllDatabases();
  }

  // Entity
  entity_dataset: boolean = true;
  entity_dossier: boolean = true;

  // Substance type
  type_mono: boolean = true;
  type_multi: boolean = true;
  type_poly: boolean = true;
  type_uvcb: boolean = true;
  type_micro: boolean = true;
  type_na: boolean = true;

  // Section
  se_representative: boolean = true;
  se_constituent: boolean = true;
  se_impurity: boolean = true;
  se_additive: boolean = true;
  se_testmaterial: boolean = false;

  getDatabases(): string {
    var databases = "";

    for (let database of this.databases) {
      if (database.IsSelected)
        databases = databases + database.Name + ',';
    }

    return databases.slice(0, -1);
  }

  getEntity(): string {
    var entity = "";
  
    if (this.entity_dataset && this.entity_dossier)
      entity = "Any";
    else if (this.entity_dataset)
      entity = "Dataset";
    else if (this.entity_dossier)
      entity = "Dossier";
  
    return entity;
  }

  getSubstanceType(): string {
    var substanceType = "";
  
    if (this.type_mono)
      substanceType = substanceType + "Monoconstituent,";
  
    if (this.type_multi)
      substanceType = substanceType + "Multiconstituent,";
  
    if (this.type_poly)
      substanceType = substanceType + "Polymer,";
  
    if (this.type_uvcb)
      substanceType = substanceType + "UVCB,";
  
    if (this.type_micro)
      substanceType = substanceType + "Microorganism,";
  
    if (this.type_na)
      substanceType = substanceType + "Unknown,";
  
    return substanceType.slice(0, -1);
  }
  
  getSection(): string {
    var substanceType = "";
  
    if (this.se_representative)
      substanceType = substanceType + "Representation,";
  
    if (this.se_constituent)
      substanceType = substanceType + "Constituent,";
  
    if (this.se_impurity)
      substanceType = substanceType + "Impurity,";
  
    if (this.se_additive)
      substanceType = substanceType + "Aditive,";
  
    if (this.se_testmaterial)
      substanceType = substanceType + "TestMaterial,";
  
    return substanceType.slice(0, -1);
  }

  selectAllDatabases() {
    for (let database of this.databases)
      database.IsSelected = true;
  }

  selectAllEntities() {
    this.entity_dataset = true;
    this.entity_dossier = true;
  }

  selectAllSubstanceTypes() {
    this.type_mono = true;
    this.type_multi = true;
    this.type_poly = true;
    this.type_uvcb = true;
    this.type_micro = true;
    this.type_na = true;
  }

  selectAllSections() {
    this.se_representative = true;
    this.se_constituent = true;
    this.se_impurity = true;
    this.se_additive = true;
    this.se_testmaterial = true;
  }

  selectAll() {
    this.selectAllDatabases();
    this.selectAllEntities();
    this.selectAllSubstanceTypes();
    this.selectAllSections();
  }
}
